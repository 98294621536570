// Définitions de base des permissions
const Permissions = {
    SUPERADMIN: 'superadmin', // Tous les droits
    ADMIN: 'admin', // Tous les droits
    CONNECTED: 'user', // Utilisateur connecté
    NOTCONNECTED: 'guest', // Utilisateur non connecté

    // Permissions pour les plateformes
    PLATEFORMEMANAGEMENT: 'plateforme-management', // Gestion de la plateforme

    // Permission Json artiste :
    JSONARTISTE: 'Json-Artiste', // JSON de l'artiste
  };

  // Fonctions utilitaires pour vérifier les permissions
  const hasPermission = (userPermission, requiredPermission) => {
    let hasRequiredPermission = false;
    if (requiredPermission === Permissions.NOTCONNECTED) {
      hasRequiredPermission = !userPermission.isAuth;
    } else if (requiredPermission === Permissions.CONNECTED) {
      hasRequiredPermission = userPermission.isAuth;
    } else {
      hasRequiredPermission = String(userPermission.permission).includes(requiredPermission) || String(userPermission.permission).includes(Permissions.ADMIN);
      hasRequiredPermission = hasRequiredPermission && userPermission.isAuth;
    }
    return hasRequiredPermission;
  };
  
  // Exporter les permissions et les fonctions
  export { Permissions, hasPermission };