import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const COOKIE_NAME = '_uasid';
const SECRET_KEY = 'secret_key'; // Vous devriez utiliser une clé plus sécurisée et idéalement la stocker de manière sécurisée

// Chiffre les données avant de les stocker dans le cookie
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Déchiffre les données du cookie
const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Crée et stocke le cookie
export const setAuthenticationCookie = (data) => {
  const encryptedData = encryptData(data);
  Cookies.set(COOKIE_NAME, encryptedData, { expires: 1 }); // Expire dans 1 jour
};

// Vérifie si l'utilisateur est authentifié via le cookie
export const isAuthenticatedViaCookie = () => {
  const cookie = Cookies.get(COOKIE_NAME);
  if (!cookie) return false;

  try {
    const decryptedData = decryptData(cookie);
    const expiration = new Date(decryptedData.expiration);
    const now = new Date();

    if (now <= expiration) {
      return decryptedData;
    }
  } catch (error) {
  }
  return false;
};
