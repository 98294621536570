class ApiService {
	static checkURL() {
		// return "https://api.theomouty.fr";
		if (window.location.href.startsWith('http://localhost')) {
			return "http://localhost:5000";
		} else {
			return "https://api.theomouty.fr";
		}
	}

	static async request(url, method, body = null) {
		const fullUrl = `${this.checkURL()}${url}`;
		const headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		};
		const config = {
			method: method,
			headers: headers,
			credentials: 'include'
		};

		if (body) {
			config.body = JSON.stringify(body);
		}

		try {
			const response = await fetch(fullUrl, config);
			if (!response.ok) {
				let error = response.statusText;
				try {
					const json = await response.json();
					error = json.message || error;
				} catch (err) {
				}
				throw new Error(error);
			}
			return response.json();
		} catch (err) {
			throw err;
		}
	}

	static get(url) {
		return this.request(url, 'GET');
	}

	static post(url, body) {
		return this.request(url, 'POST', body);
	}

	static put(url, body) {
		return this.request(url, 'PUT', body);
	}

	static delete(url, body) {
		return this.request(url, 'DELETE', body);
	}
}

const logoutUser = async () => {
	try {
		// Remplacez 'post' par 'get' si votre API utilise GET pour la déconnexion
		const data = await ApiService.post('/v1/logout');
		window.location.href = "/";
		return data;
	} catch (error) {
		console.error("Erreur lors du logout:", error);
		window.location.href = "/";
	}
};

export {
	ApiService,
	logoutUser
};