import React, { Component } from 'react'
import { GridLoader } from 'react-spinners'

export class Loader extends Component {
  render() {
    const overlayStyle = {
      position: 'fixed', // Positionne le loader de manière fixe à l'écran
      top: 0, // À partir du haut
      left: 0, // À partir de la gauche
      width: '100%', // Largeur totale
      height: '100%', // Hauteur totale
      zIndex: 1000, // S'assure qu'il est au-dessus de tout autre contenu
      display: 'flex', // Utilise Flexbox pour le centrage
      justifyContent: 'center', // Centre horizontalement
      alignItems: 'center', // Centre verticalement
    };

    return (
      <div style={overlayStyle}>
        <GridLoader
          color="#36d7b7"
          margin={10}
          size={30}
        />
      </div>
    );
  }
}

// export error
export class Error extends Component {
  render() {
    const { message } = this.props;

    return (
      <div className="error-wrapper justify-content-center align-items-center">
        <div className="alert alert-danger text-center font-weight-bold border border-danger border-4" role="alert">
          <h1>⚠️ ERREUR ⚠️</h1>
          <h4 className='font-weight-bold'>{message}</h4>
          {/* actualiser */}
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => window.location.reload()}>
            Actualiser
          </button>
        </div>
      </div>
    );
  }
}

export default Loader