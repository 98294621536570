import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IsAuthenticated } from '../../API/v1/isAuthenticated';
import { Permissions } from './permissions';
import Loader from '../tools/Loader';

function PermissionRoute({ component: Component, requiredPermissions, ...rest }) {
    return (
        <IsAuthenticated.Consumer>
            {({ isAuth, permission, loading }) => {
                let hasRequiredPermission = false;

                if (requiredPermissions === Permissions.NOTCONNECTED) {
                    hasRequiredPermission = !isAuth;
                } else if (requiredPermissions === Permissions.CONNECTED) {
                    hasRequiredPermission = isAuth;
                } else {
                    hasRequiredPermission = String(permission).includes(requiredPermissions) || String(permission).includes(Permissions.ADMIN);
                    hasRequiredPermission = hasRequiredPermission && isAuth;
                }

                if (loading) {
                    // Lorsque les données sont en cours de chargement, vous pouvez afficher un indicateur de chargement
                    return <Loader />
                } else {
                    // Lorsque les données ne sont plus en cours de chargement
                    return (
                        <Route
                            {...rest}
                            render={props =>
                                hasRequiredPermission ? (
                                    <Component {...props} />
                                ) : (
                                    <Redirect to={{ pathname: '/dashboard' }} />
                                )
                            }
                        />
                    );
                }
            }}
        </IsAuthenticated.Consumer>
    );
}

export default PermissionRoute;
